import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import TestResult from "../../components/test-result";


const GPCPage = () => {
  const [gpc, setGPC] = useState(undefined);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setGPC(navigator.globalPrivacyControl);
    if (navigator.globalPrivacyControl && typeof navigator.globalPrivacyControl === 'boolean') {
      setResult(true);
    } else {
      setResult(false);
    }
  }, [])

  return (
    <Layout>
      <h1>Navigator.globalPrivacyControl</h1>
      <p>
        The <code>navigator.globalPrivacyControl</code> property returns the user's Global Privacy Control setting. This
        setting indicates whether the user consents to a website or service selling or sharing their personal
        information
        with third parties.
      </p>

      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={result}>
            <div className="border-2 p-5">
              <div>
                <code>navigator.globalPrivacyControl = {String(gpc)}</code>
              </div>
            </div>
          </TestResult>
        </div>
      </div>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Document.referrer"/>
export default GPCPage;
